import request from "@/utils/request.js"
import Qs from "qs"


//获取商家详情
export const getShopList_api = params => {
  return request({
    method: 'get',
    url: 'api/v1/shop/list',
    params,
  });
}

//修改门店账号

export const changeShopAcc_api = data => {
  return request({
    method: 'post',
    url: 'api/v1/shop/account/update',
    data: Qs.stringify(data)
  });
}
//获取统计详情
export const getShopInfo_api = params => {
  return request({
    method: 'get',
    url: 'api/v1/shop/statistic/info',
    params,
  });
}
//获取店员列表
export const getShopMemberList_api = params => {
  return request({
    method: 'get',
    url: 'api/v1/shop/user/list',
    params,
  });
}
//添加店员
export const addShopMemder_api = data => {
  return request({
    method: 'post',
    url: 'api/v1/shop/user/add',
    data: Qs.stringify(data)
  });
}
//修改店员信息

export const changeShopMemder_api = data => {
  return request({
    method: 'post',
    url: 'api/v1/shop/user/update',
    data: Qs.stringify(data)
  });
}

//修改门店信息

export const changeShopInfo_api = data => {
  return request({
    method: 'post',
    url: 'api/v1/shop/info/update',
    data: Qs.stringify(data)
  });
}

//删除门店成员

export const deleteShopMember_api = data => {
  return request({
    method: 'post',
    url: 'api/v1/shop/user/delete',
    data: Qs.stringify(data)
  });
}

//获取黑名单店员列表
export const getBlackListShopMemberList_api = params => {
  return request({
    method: 'get',
    url: 'api/v1/shop/blacklistuser/list',
    params,
  });
}
//添加黑名单店员
export const addBlacListShopMemder_api = data => {
  return request({
    method: 'post',
    url: 'api/v1/shop/blacklistuser/add',
    data: Qs.stringify(data)
  });
}
//修改黑名单店员信息

export const changeBlacListShopMemder_api = data => {
  return request({
    method: 'post',
    url: 'api/v1/shop/blacklistuser/update',
    data: Qs.stringify(data)
  });
}
//删除门店黑名单成员

export const deleteBlacListShopMember_api = data => {
  return request({
    method: 'post',
    url: 'api/v1/shop/blacklistuser/delete',
    data: Qs.stringify(data)
  });
}




//获取结算记录

export const getShopSettleList_api = params => {
  return request({
    method: 'get',
    url: 'api/v1/shop/settle/list',
    params
  });
}
//获取结算账号

export const getShopSettleBankInfo_api = params => {
  return request({
    method: 'get',
    url: 'api/v1/settle/bankInfo',
    params
  });
}
//获取开票信息
export const getShopSettleBillInfo_api = params => {
  return request({
    method: 'get',
    url: 'api/v1/settle/billInfo',
    params
  });
}
//获取结算列表
export const getSettleList_api = params => {
  return request({
    method: 'get',
    url: 'api/v1/settle/list',
    params
  });
}
//获取伊藤（超市连锁）结算列表
export const VgetSettleList_api = params => {
  return request({
    method: 'get',
    url: 'api/v2/settle/list',
    params
  });
}
//获取超市连锁单门店结算列表
export const DgetSettleList_api = params => {
  return request({
    method: 'get',
    url: 'api/v2/store/settle/list',
    params
  });
}
//申请发票
export const billApply_api = data => {
  return request({
    method: 'post',
    url: 'api/v1/settle/billApply',
    data: Qs.stringify(data)
  });
}
//获取生鲜库存商品列表

export const getChainGoodList_api = params => {
  return request({
    method: 'get',
    url: 'api/v1/supermarket/chainGood/freshList',
    params
  });
}
//获取非生鲜商品列表

export const getNofreshListGoodList_api = params => {
  return request({
    method: 'get',
    url: 'api/v1/supermarket/chainGood/list',
    params
  });
}
//获取库存商品列表(连锁)

export const getChainGoodList_api1 = params => {
  return request({
    method: 'get',
    url: 'api/v1/chainGood/list',
    params
  });
}
//添加非生鲜库存
export const getChainGoodAdd_api = data => {
  return request({
    method: 'post',
    url: 'api/v1/supermarket/chainGood/add',
    data: Qs.stringify(data)
  });
}
//添加生鲜库存

export const freshAddchainGood = data => {
  return request({
    method: 'post',
    url: 'api/v1/supermarket/chainGood/freshAdd',
    data: Qs.stringify(data)
  });
}
//添加库存(连锁)
export const getChainGoodAdd_api1 = data => {
  return request({
    method: 'post',
    url: 'api/v1/chainGood/add',
    data: Qs.stringify(data)
  });
}
//编辑库存
export const getChainGoodChange_api = data => {
  return request({
    method: 'post',
    url: 'api/v1/chainGood/change',
    data: Qs.stringify(data)
  });
}

//获取生鲜出库记录
export const chainGoodGoodRecord_api = params => {
  return request({
    method: 'get',
    url: 'api/v1/supermarket/chainGood/freshGoodRecord',
    params
  });
}
export const goodRecord_api = params => {
  return request({
    method: 'get',
    url: '/api/v1/chainGood/goodRecord',
    params
  });
}
//非生鲜出库记录

export const supermarketchainGoodGoodRecord_api = params => {
  return request({
    method: 'get',
    url: 'api/v1/supermarket/chainGood/goodRecord',
    params
  });
}
//导出

export const chainGoodExport_api = params => {
  return request({
    method: 'get',
    url: 'api/v1/chainGood/export',
    params
  });
}
//门店列表-下拉选择
export const chainGoodshopList_api = params => {
  return request({
    method: 'get',
    url: 'api/v1/supermarket/chainGood/shopList',
    params
  });
}
//识别营业执照
export const getSlicenseNumber_api =data=>{
  return  request({
    method: 'post',
    url: 'api/v1/shop/info/distinguishPicture',
    data: Qs.stringify(data)
  });
}

//根据门店获取商品分类
export const goodSeriesCategory = params => {
  return request({
    method: 'get',
    url: 'api/v1/goodSeries/category',
    params
  });
}

//根据门店获取商品分类
export const supermarketchainGoodchange = data => {
  return request({
    method: 'post',
    url: 'api/v1/supermarket/chainGood/change',
    data: Qs.stringify(data)
  });
}

//获取留存列表
export const getyesterdayGood = params => {
  return request({
    method: 'get',
    url: '/api/v1/supermarket/chainGood/yesterdayGood',
    params
  });
}


